import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { KantarListesiComponent } from "../kantar-listesi/kantar-listesi.component";
import { AktifYukleme } from "../Models/aktif-yukleme";
import { KantarKayitlari } from "../Models/kantar-kayitlari";
import { GemiListeleriService } from "../Servisler/gemi-listeleri.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import * as XLSX from "xlsx";
// import { DatePipe, formatDate, formatCurrency, formatPercent, formatNumber } from '@angular/common';


@Component({
  selector: "app-aktif-liste",
  templateUrl: "./aktif-liste.component.html",
  styleUrls: ["./aktif-liste.component.css"],
})
export class AktifListeComponent implements OnInit {
  @Output() submitClicked = new EventEmitter<any>();


  jwtHelper: JwtHelperService = new JwtHelperService();
  decodeToken: any;

  kantarKayitListesi: KantarKayitlari[];
  kantarKayitDurum: number;
  yesilYurt : number;
  

  constructor(
    public dialogListe: MatDialogRef<AktifListeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: [],
    private gemi: GemiListeleriService,
    public dialog: MatDialog, 
    // private locale: string
    
  ) {}

  ngOnInit() {
   
    this.decodeToken = this.jwtHelper.decodeToken(
      localStorage.getItem("TurcaSoft")
    ); 

     this.yesilYurt = this.decodeToken["_ytur"];

  
    if (this.data["dtkntrDurum"] == "True") this.kantarKayitDurum = 1;
    else this.kantarKayitDurum = 0;

    

    if (this.decodeToken["_ytur"] == "True") {
      this.yesilYurt = 1; 
    } else {
      this.yesilYurt = 0;
    }
    

    


// formatNumber(13.674566, this.locale, "1.2-3");
//      const foo = formatCurrency(1362.4567, this.locale, "");
//     formatPercent(0.77, this.locale);
//      console.log(foo);

  }

  kantarKayitlari(item: number) {
    this.gemi.kantarKayitListele(item).subscribe((response) => {
      this.kantarKayitListesi = response;

      if (this.kantarKayitListesi) this.openKantarListesi();
    });
  }

  openKantarListesi(): void {
    let dialogRef = this.dialog.open(KantarListesiComponent, {
      disableClose: true,
      autoFocus: false,
      height: "100vh",
      width: "100vw",

      maxHeight: "100vh",
      maxWidth: "100vw",

      data: this.kantarKayitListesi,
    });

    dialogRef.afterClosed().subscribe((result) => {});

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(
      (result) => {
        ///  dialogSubmitSubscription.unsubscribe();
      }
    );
  }

  saveMessage() {
    this.submitClicked.emit();
    this.dialogListe.close();
  }

  closeDialog() {
    this.dialogListe.close();
  }

  excelExport() {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.data["dtaktifGemiListesi"]
    );

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Limandaki Gemiler");

    /* save to file */
    XLSX.writeFile(wb, "Limandaki_Gemi-listesi.xlsx");
  }
}
