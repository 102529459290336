import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { YoldakiGemiler } from '../Models/yoldaki-gemiler';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-yoldaki-liste',
  templateUrl: './yoldaki-liste.component.html',
  styleUrls: ['./yoldaki-liste.component.css']
})
export class YoldakiListeComponent implements OnInit {

  @Output() submitClicked = new EventEmitter<any>();

  constructor(
    public dialogListe: MatDialogRef<YoldakiListeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: YoldakiGemiler[]
  ) {}

  ngOnInit() {
  }

  saveMessage() {
    this.submitClicked.emit();
    this.dialogListe.close();
  }

  closeDialog() {
    this.dialogListe.close();
  }

  excelExport() {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.data);
  
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Yoldaki Gemiler');
  
    /* save to file */
    XLSX.writeFile(wb, 'Yoldaki_Gemiler-listesi.xlsx');
  }
}
