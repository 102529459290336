import { Component, HostBinding, HostListener, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NgxEncryptCookieService } from "ngx-encrypt-cookie";
import { AktifListeComponent } from "../aktif-liste/aktif-liste.component";
import { DemirListeComponent } from "../demir-liste/demir-liste.component";
import { AktifYukleme } from "../Models/aktif-yukleme";
import { DemirdekiGemiler } from "../Models/demirdeki-gemiler";
import { MenuListesi } from "../Models/menu-listesi";
import { YoldakiGemiler } from "../Models/yoldaki-gemiler";
import { GemiListeleriService } from "../Servisler/gemi-listeleri.service";
import { YoldakiListeComponent } from "../yoldaki-liste/yoldaki-liste.component";

@Component({
  selector: "app-anasayfa",
  templateUrl: "./anasayfa.component.html",
  styleUrls: ["./anasayfa.component.css"],
})
export class AnasayfaComponent implements OnInit {

  // settings: WeatherSettings = {
  //   location: {
  //     cityName: 'Samsun'
  //   },
  //   backgroundColor: '#347c57',
  //   color: '#ffffff',
  //   width: '300px',
  //   height: 'auto',
  //   showWind: false,
  //   scale: TemperatureScale.CELCIUS,
  //   forecastMode: ForecastMode.DETAILED,
  //   showDetails: false,
  //   showForecast: true,
  //   layout: WeatherLayout.WIDE,
  //   language: 'en'
  // };


  tonajToplam: number;
  islemTonajToplam: number;
  kalanTonajToplam: number;
  draftDurumu:boolean;

  firmaUnvani: string;

  aktifGemiListesi: AktifYukleme[];
  demirListele: DemirdekiGemiler[];
  yoldakiListele: YoldakiGemiler[];

  menuList: MenuListesi[];

  jwtHelper: JwtHelperService = new JwtHelperService();
  decodeToken: any;

  loading = false;
  ktDurum: boolean;
  mtDurum: boolean;
  yesilYurt: number;

  demirAlanGemiSayisi : number;
  yoldakiGemiSayisi : number;
  

  constructor(
    private router: Router,
    private gemi: GemiListeleriService,
    public dialog: MatDialog,
    private cookieService: NgxEncryptCookieService
  ) {}

  ngOnInit() {
    this.decodeToken = this.jwtHelper.decodeToken(
      localStorage.getItem("TurcaSoft")
    );

     
    this.firmaUnvani = this.decodeToken["_unvan"];
    this.mtDurum = this.decodeToken["_tur"];
    this.ktDurum = this.decodeToken["_ktur"];

    if (this.decodeToken["_ytur"] == "True") {
      this.yesilYurt = 1; 
    } else {
      this.yesilYurt = 0;
    }
     


 

    
   //  console.log(this.mtDurum)

    if (this.decodeToken) {
      this.menuList = [
        {
          menuId: 1,
          menuAd: "AKTİF YÜKLEME VE BOŞALTMALAR",
          menuAciklama:
            "Sistem tarafından adınıza kayıtlı limanda halihazırda işlem görmekte (Yükleme yada Boşaltma) olan gemilere ait bilgiler.",
          resimAdres: "assets/images/gemi_1.png",
          id: this.decodeToken["_id"],
          durum: this.decodeToken["_tur"],
        },
        {
          menuId: 2,
          menuAd: "DEMİRDEKİ GEMİLER",
          resimAdres: "assets/images/gemi_7.png",
          menuAciklama:
            "Sistem tarafından adınıza kayıtlı liman sahasına henüz alınamamış açıkta Alargada (Demirde)  beklemekte  olan gemilerinize ait bilgiler.",
          id: this.decodeToken["_id"],
          durum: this.decodeToken["_tur"],
        },
        {
          menuId: 3,
          menuAd: "YOLDAKİ GEMİLER",
          menuAciklama:
            "Sistem tarafından adınıza kayıtlı henüz limanımıza ulaşmamış, yoldaki gemilerinize ait bilgiler.",
          resimAdres: "assets/images/gemi_9.png",
          id: this.decodeToken["_id"],
          durum: this.decodeToken["_tur"],
        },
      ];
      if (this.decodeToken["_tur"] == 1) this.girisListesi(); 
    }
  }

  girisListesi() {
  
    this.gemi.aktifListele().subscribe((response) => {
      this.loading = false;
      this.aktifGemiListesi = response;
    //  console.log(this.aktifGemiListesi)
    
    });

    this.gemi.demirListele().subscribe((response) => {
      //console.log("demir listesi " + response["kayitSayisi"]);
      this.demirAlanGemiSayisi = response["kayitSayisi"];
    });

    this.gemi.yoldakiListele().subscribe((response) => {
    //  console.log("yoldaki listesi " + response["kayitSayisi"]);
      this.yoldakiGemiSayisi = response["kayitSayisi"];
    });    
  };

  // gemiListeleri(items) {
  //   if (items["menuId"] == 1) {
  //     this.gemi.aktifListele(items).subscribe((response) => {
  //       this.aktifGemiListesi = response;
  //       if (this.aktifGemiListesi) this.openAktifListesi();
  //     });
  //   } else if (items["menuId"] == 2) {
  //     this.gemi.demirListele(items).subscribe((response) => {
  //       this.demirListele = response;
  //       if (this.demirListele) this.openDemirListesi();
  //     });
  //   } else if (items["menuId"] == 3) {
  //     this.gemi.yoldakiListele(items).subscribe((response) => {
  //       this.yoldakiListele = response;
  //      if (this.yoldakiListele) this.openYoldakiListesi();
  //   });
  //  }
  //}

  genelHesapla() {
    this.tonajToplam = 0;
    this.islemTonajToplam = 0;
    this.kalanTonajToplam = 0;
    for (let items of this.aktifGemiListesi) {
      this.tonajToplam = this.tonajToplam + items.tonaj;
      this.islemTonajToplam = this.islemTonajToplam + items.islemTonaj;
      this.kalanTonajToplam = this.kalanTonajToplam + items.kalanTonaj;
    }
  }

  gemiListeleri(items) {
    this.loading = true;
    if (items["menuId"] == 1) {
      this.gemi.aktifListele().subscribe((response) => {
        this.loading = false;
        this.aktifGemiListesi = response;
        if (this.aktifGemiListesi) {
          this.genelHesapla();
          if (this.tonajToplam)
            this.openAktifListesi(
              this.tonajToplam,
              this.islemTonajToplam,
              this.kalanTonajToplam,
              this.ktDurum
              
            );
        }
      });
    } else if (items["menuId"] == 2) {
      this.gemi.demirListele().subscribe((response) => {
        this.loading = false;
     //   console.log(response["kayitSayisi"]);
        this.demirListele = response["data"];
        if (this.demirListele) this.openDemirListesi();
      });
    } else if (items["menuId"] == 3) {
      this.gemi.yoldakiListele().subscribe((response) => {
        this.loading = false;
        this.yoldakiListele = response["data"];
     //   console.log(this.yoldakiListele)
        if (this.yoldakiListele) this.openYoldakiListesi();
      });
    }
  }

  openAktifListesi(
    _dttonajToplam: number,
    _dtislemTonajToplam: number,
    _dtkalanTonajToplam: number,
    _dtkntrDurum: boolean
    
  ): void {
    let dialogRef = this.dialog.open(AktifListeComponent, {
      disableClose: true,
      autoFocus: false,
      height: "100vh",
      width: "100vw",
      maxHeight: "100vh",
      maxWidth: "100vw",

      data: {
        dtaktifGemiListesi: this.aktifGemiListesi,
        dttonajToplam: _dttonajToplam,
        dtislemTonajToplam: _dtislemTonajToplam,
        dtkalanTonajToplam: _dtkalanTonajToplam,
        dtkntrDurum: _dtkntrDurum,
        
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(
      (result) => {
        ///  dialogSubmitSubscription.unsubscribe();
      }
    );
  }

  openDemirListesi(): void {
    let dialogRef = this.dialog.open(DemirListeComponent, {
      disableClose: true,
      autoFocus: false,
      height: "100vh",
      width: "100vw",

      maxHeight: "100vh",
      maxWidth: "100vw",

      data: this.demirListele,
    });

    dialogRef.afterClosed().subscribe((result) => {});

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(
      (result) => {
        ///  dialogSubmitSubscription.unsubscribe();
      }
    );
  }

  openYoldakiListesi(): void {
    let dialogRef = this.dialog.open(YoldakiListeComponent, {
      disableClose: true,
      autoFocus: false,
      height: "100vh",
      width: "100vw",

      maxHeight: "100vh",
      maxWidth: "100vw",

      data: this.yoldakiListele,
    });

    dialogRef.afterClosed().subscribe((result) => {});

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(
      (result) => {
        ///  dialogSubmitSubscription.unsubscribe();
      }
    );
  }

  kapat() {
    localStorage.removeItem("TurcaSoft");
    // this.cookieService.deleteAll();
    return this.router.navigateByUrl("yesilyurtliman");
  }
}
