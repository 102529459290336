import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-kutusu',
  templateUrl: './dialog-kutusu.component.html',
  styleUrls: ['./dialog-kutusu.component.css']
})
export class DialogKutusuComponent implements OnInit {

  @Output() submitClicked = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<DialogKutusuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  saveMessage() {
  //  this.submitClicked.emit(this.data);
    this.submitClicked.emit();
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
