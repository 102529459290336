import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DemirdekiGemiler } from '../Models/demirdeki-gemiler';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-demir-liste',
  templateUrl: './demir-liste.component.html',
  styleUrls: ['./demir-liste.component.css']
})
export class DemirListeComponent implements OnInit {

  @Output() submitClicked = new EventEmitter<any>();

  constructor(
    public dialogListe: MatDialogRef<DemirListeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DemirdekiGemiler[]
  ) {}

  ngOnInit() {
  }

  saveMessage() {
    this.submitClicked.emit();
    this.dialogListe.close();
  }

  closeDialog() {
    this.dialogListe.close();
  }

  excelExport() {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.data);
  
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Demirdeki Gemiler');
  
    /* save to file */
    XLSX.writeFile(wb, 'Demirdeki_Gemiler-listesi.xlsx');
  }
}
