import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class TurcasoftBekciService {
  jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.jwtHelper.isTokenExpired(localStorage.getItem("TurcaSoft")))
      return true;
    else {
      this.router.navigate(["login"]);
      return false;
    }
  }
}
