import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NgxEncryptCookieService } from "ngx-encrypt-cookie";
import { DialogKutusuComponent } from "../dialog-kutusu/dialog-kutusu.component";
import { KullaniciService } from "../Servisler/kullanici.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  jwtHelper: JwtHelperService = new JwtHelperService();
  decodeToken: any;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public kullanici: KullaniciService,
    private router: Router,
    private cookieService: NgxEncryptCookieService
  ) {}

  ngOnInit() {
    this.decodeToken = this.jwtHelper.decodeToken(
      localStorage.getItem("TurcaSoft")
    );

    this.loginForm = this.formBuilder.group({
      kullaniciAdi: ["", Validators.required],
      parola: ["", Validators.required],
      beniHatirla: [false],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.loginForm.reset();
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.loginForm.value, null, 4));

    //   this.kullaniciservice.login(this.loginForm.value);

    // this.router.navigateByUrl("anaSayfa");

    this.formDataGonder();
  }

  formDataGonder() {
    this.kullanici.login(this.loginForm.value).subscribe((response) => {
      if (!this.jwtHelper.isTokenExpired(response["sonucToken"])) {
        localStorage.setItem("TurcaSoft", response["sonucToken"]);

        if (this.loginForm.get("beniHatirla").value) {
          const cookieExists: boolean = this.cookieService.check("13e2b3ea436f0ab5d56f4f3c24ca9388");

          if (!cookieExists) {
            // var key = this.cookieService.generateKey();

            this.cookieService.set(
              "13e2b3ea436f0ab5d56f4f3c24ca9388",
              this.loginForm.get("kullaniciAdi").value,
              true,
              "2d0fe7dc2691a5f90b2791395628a88c", new Date(Date.now() + 30*24*60*60*1000)
            );
            this.cookieService.set(
              "8287458823facb8ff918dbfabcd22ccb",
              this.loginForm.get("parola").value,
              true,
              "2d0fe7dc2691a5f90b2791395628a88c", new Date(Date.now() + 30*24*60*60*1000)
            );
          };
        };

        this.router.navigateByUrl("anasayfa");
      } else {
        this.openDialog("Uyarı!", response["sonucAciklama"], "Tamam", null);
        //this.onReset();
      }
    });
  }

  openDialog(
    _baslik: string,
    _mesaj: string,
    _bntOk: string,
    _btnIptal: string
  ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";

    let dialogRef = this.dialog.open(DialogKutusuComponent, {
      disableClose: true,
      autoFocus: true,
      width: "45%",

      data: {
        baslik: _baslik,
        mesaj: _mesaj,
        bntOk: _bntOk,
        btnIptal: _btnIptal,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(
      (result) => {
        ///  dialogSubmitSubscription.unsubscribe();
      }
    );
  }
}
