import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { DialogKutusuComponent } from "./dialog-kutusu/dialog-kutusu.component";
import { AnasayfaComponent } from "./anasayfa/anasayfa.component";
import { AktifListeComponent } from "./aktif-liste/aktif-liste.component";
import { NgxPrintModule } from "ngx-print";
import { DemirListeComponent } from "./demir-liste/demir-liste.component";
import { YoldakiListeComponent } from "./yoldaki-liste/yoldaki-liste.component";
import { KantarListesiComponent } from "./kantar-listesi/kantar-listesi.component";
import { TurcasoftOnlemeService } from "./Servisler/turcasoft-onleme.service";
import { ngxLoadingAnimationTypes, NgxLoadingModule } from "ngx-loading";
import { CookieService } from "ngx-cookie-service";
import { KapanisSayfasiComponent } from "./kapanis-sayfasi/kapanis-sayfasi.component";
import { OnSayfaComponent } from "./on-sayfa/on-sayfa.component";
import { NgxEncryptCookieService } from "ngx-encrypt-cookie";
import { NumberformatPipe } from './Models/numberformat.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DialogKutusuComponent,
    AnasayfaComponent,
    AktifListeComponent,
    DemirListeComponent,
    YoldakiListeComponent,
    KantarListesiComponent,
    KapanisSayfasiComponent,
    OnSayfaComponent,
    NumberformatPipe,
    

  ],

  entryComponents: [
    DialogKutusuComponent,
    AktifListeComponent,
    DemirListeComponent,
    YoldakiListeComponent,
    KantarListesiComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,

    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPrintModule,
    MatProgressSpinnerModule,

    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      // chasingDots   +++
      // circle
      // circleSwish   +++
      // cubeGrid
      // doubleBounce
      // none
      // pulse
      // rectangleBounce
      // rotatingPlane
      // threeBounce
      // wanderingCubes
      backdropBackgroundColour: "rgba(0,0,0,0.1)",
      backdropBorderRadius: "4px",
      primaryColour: "#ffffff",
      secondaryColour: "#ffffff",
      tertiaryColour: "#ffffff",
    }),
    

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TurcasoftOnlemeService,
      multi: true,
    },
    CookieService,

    NgxEncryptCookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
