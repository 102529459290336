import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numberformat",
})
export class NumberformatPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      return value
        .toString()
        .replace(",", "|")
        .replace(".", ",")
        .replace("|", ".");
    }
  }
}
