import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AktifListeComponent } from "./aktif-liste/aktif-liste.component";
import { AnasayfaComponent } from "./anasayfa/anasayfa.component";
import { KapanisSayfasiComponent } from './kapanis-sayfasi/kapanis-sayfasi.component';
import { LoginComponent } from "./login/login.component";
import { OnSayfaComponent } from './on-sayfa/on-sayfa.component';
import { TurcasoftBekciService } from "./Servisler/turcasoft-bekci.service";

const routes: Routes = [
  { path: "", component: OnSayfaComponent },
  
  { path: "login", component: LoginComponent },
  {
    path: "anasayfa",
    component: AnasayfaComponent,
    canActivate: [TurcasoftBekciService],
  },

  {
    path: "aktifliste",
    component: AktifListeComponent,
    canActivate: [TurcasoftBekciService],
  },
 
  {
    path: "yesilyurtliman",
    component: KapanisSayfasiComponent,
  },

  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
