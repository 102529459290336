import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, pipe, Observable } from 'rxjs';
import { retry, catchError } from "rxjs/operators";
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class KullaniciService {
  env = environment;

  constructor(private http: HttpClient) {}

  path = this.env.ihaleUrl + "Kullanici/";

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("Bir hata oluştu:", error.error.message);
    } else {
      console.error(
        `Servisten dönen kod ${error.status}, ` + `çerçeves: ${error.error}`
      );
    }
    return throwError(
      "Bilinmeyen bir hata oluştu; lütfen daha sonra tekrar deneyiniz."
    );
  }

  login(item) {
      return this.http
      .post(this.path + "login", item, this.httpOptions)
      .pipe(retry(0), catchError(this.handleError));
  }

}

