import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { throwError, pipe, Observable } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { AktifYukleme } from "../Models/aktif-yukleme";
import { DemirdekiGemiler } from "../Models/demirdeki-gemiler";
import { YoldakiGemiler } from "../Models/yoldaki-gemiler";
import { KantarKayitlari } from "../Models/kantar-kayitlari";

@Injectable({
  providedIn: "root",
})
export class GemiListeleriService {
  env = environment;

  constructor(private http: HttpClient) {}

  path = this.env.ihaleUrl + "Ortak/";

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("Bir hata oluştu:", error.error.message);
    } else {
      console.error(
        `Servisten dönen kod ${error.status}, ` + `çerçeves: ${error.error}`
      );
    }
    return throwError(
      "Bilinmeyen bir hata oluştu; lütfen daha sonra tekrar deneyiniz."
    );
  }

  // aktifListele(items): Observable<AktifYukleme[]> {
  //   return this.http
  //     .post<AktifYukleme[]>(this.path + "aktifgemiler", items, this.httpOptions)
  //     .pipe(retry(0), catchError(this.handleError));
  // }

  aktifListele(): Observable<AktifYukleme[]> {
    return this.http
      .get<AktifYukleme[]>(this.path + "aktifgemiler", this.httpOptions)
      .pipe(retry(0), catchError(this.handleError));
  };

  // demirListele(items): Observable<DemirdekiGemiler[]> {
  //   return this.http
  //     .post<DemirdekiGemiler[]>(
  //       this.path + "demirdekiGemiler",
  //       items,
  //       this.httpOptions
  //     )
  //     .pipe(retry(0), catchError(this.handleError));
  // }

  demirListele(): Observable<DemirdekiGemiler[]> {
    return this.http
      .get<DemirdekiGemiler[]>(this.path + "demirdekiGemiler", this.httpOptions)
      .pipe(retry(0), catchError(this.handleError));
  };

  // yoldakiListele(items): Observable<YoldakiGemiler[]> {
  //   return this.http
  //     .post<YoldakiGemiler[]>(
  //       this.path + "yoldakiGemiler",
  //       items,
  //       this.httpOptions  
  //     )
  //     .pipe(retry(0), catchError(this.handleError));
  // }

  yoldakiListele(): Observable<YoldakiGemiler[]> {
    return this.http
      .get<YoldakiGemiler[]>(this.path + "yoldakiGemiler", this.httpOptions)
      .pipe(retry(0), catchError(this.handleError));
  }

  kantarKayitListele(item: number): Observable<KantarKayitlari[]> {
    return this.http
      .post<KantarKayitlari[]>(
        this.path + "kantarraporu",
        item,
        this.httpOptions
      )
      .pipe(retry(0), catchError(this.handleError));
  }
}
