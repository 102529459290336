import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TurcasoftOnlemeService {

  jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (localStorage.getItem("TurcaSoft") != null) {
      const clonedReq = req.clone({
        headers: req.headers.set(
          "authorization",
          "Bearer " + localStorage.getItem("TurcaSoft")
        ),
      });
      return next.handle(clonedReq).pipe(
        tap(
          (succ) => {
            if (
              this.jwtHelper.isTokenExpired(localStorage.getItem("TurcaSoft"))
            ) {
              localStorage.removeItem("TurcaSoft");
              this.router.navigate(["login"]);
            }
          },
          (err) => {
            localStorage.removeItem("TurcaSoft");
            this.router.navigate(["login"]);

            if (err.status == 401) {
              /*

              */
            }
          }
        )
      );
    } else return next.handle(req.clone());
  }
}