import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NgxEncryptCookieService } from "ngx-encrypt-cookie";
import { KullaniciService } from "../Servisler/kullanici.service";

@Component({
  selector: "app-on-sayfa",
  templateUrl: "./on-sayfa.component.html",
  styleUrls: ["./on-sayfa.component.css"],
})
export class OnSayfaComponent implements OnInit {
  jwtHelper: JwtHelperService = new JwtHelperService();
  decodeToken: any;

  constructor(
    public kullanici: KullaniciService,
    private router: Router,
    private cookie: NgxEncryptCookieService
  ) {}

  ngOnInit() {
    localStorage.removeItem("TurcaSoft");

    const cookieExists: boolean = this.cookie.check(
      "13e2b3ea436f0ab5d56f4f3c24ca9388"
    );

    if (cookieExists) {
      this.kayitliGiris({
        kullaniciAdi: this.cookie.get(
          "13e2b3ea436f0ab5d56f4f3c24ca9388",
          true,
          "2d0fe7dc2691a5f90b2791395628a88c"
        ),
        parola: this.cookie.get(
          "8287458823facb8ff918dbfabcd22ccb",
          true,
          "2d0fe7dc2691a5f90b2791395628a88c"
        ),
      });
    } else this.router.navigateByUrl("login");
  }

  kayitliGiris(items) {
    this.kullanici.login(items).subscribe((response) => {
      if (!this.jwtHelper.isTokenExpired(response["sonucToken"])) {
        localStorage.setItem("TurcaSoft", response["sonucToken"]);
        this.router.navigateByUrl("anasayfa");
      } else {
        this.router.navigateByUrl("login");
      }
    });
  }
}
